@use '../../styles/yr' as *;

.how-to-dialog {
  text-align: center;
}

.how-to-dialog__title {
  @include h1;

  margin-top: size(3);
  margin-bottom: size(7);

  &:focus {
    outline: 0;
  }
}

.how-to-dialog__icon {
  margin-bottom: size(2);
  margin-left: auto;
  margin-right: auto;
}

.how-to-dialog__text {
  margin-bottom: size(3);
}

.how-to-dialog__warning {
  @include small-text;
}
