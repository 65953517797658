@use '../../styles/yr' as *;

.no-result {
  text-align: center;
}

.no-result__title {
  @include h2;

  margin-bottom: size(2);
}

.no-result__text {
  margin-top: size(2);
}
