@use '../../styles/yr' as *;

.footer {
  padding: size(7) 0 size(3) 0;
  @include mq-gt(570px) {
    padding: size(3) 0;
  }
}

.footer__logos {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: size(2);
}

.footer__logo--nrk,
.footer__logo--met {
  margin-right: size(3);
}

.footer__logo-image--nrk-img {
  height: size(3);
}

.footer__logo-image--nrk-img {
  height: rem(29px);
}

.footer__logo-image--reiseradioen-img {
  height: rem(40px);
}

.footer__actions {
  display: flex;
  flex-wrap: wrap;
}

.footer__action {
  text-decoration: underline;
  white-space: nowrap;
  padding: size(1) 0;
  margin-right: size(2);
}
