@use '../../styles/yr' as *;

.search-page__heading {
  @include h1;

  margin-top: size(6);
  margin-bottom: size(2);
}

.search-page__list {
  margin-bottom: size(3);
}

.search-page__list-item {
  background-color: white;
  padding: size(1);

  border-radius: size(1);

  & + & {
    margin-top: size(2);
  }
}

.search-page__list-item-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: size(4);
}

.search-page__list-item-title {
  @include large-text;
}

.search-page__list-item-subtitle {
  @include small-text;
}

.search-page__result-heading {
  @include small-text;

  margin-top: size(1);
  margin-bottom: size(2);
}

.search-page__result-subheading {
  @include h3;

  margin-bottom: size(1);
}

.search-page__sorry {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: rem(70px);
}

.search-page__sorry-heading,
.search-page__sorry-subheading {
  @include h2;
}

.search-page__sorry-subheading {
  margin-bottom: size(1);
}

.search-page__form {
  display: flex;
}

.search-page__input-container {
  position: relative;
  width: 100%;
  z-index: 0;
}

.search-page__input {
  @include input-text;

  background-color: transparent;
  height: size(6);
  width: 100%;
  padding-left: size(6);
  border-radius: size(1);
  border: 0;

  &::placeholder {
    color: $color-pale-blue;
  }

  @include hover {
    & + .search-page__input-background {
      border: 2px solid $color-mid-blue;
    }
  }

  &:active,
  &:focus {
    outline: none;

    & + .search-page__input-background {
      border: 2px solid $color-deep-blue;
    }
  }
}

.search-page__input-background {
  border-radius: rem(8px);
  border: 0;
  background-color: $color-white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  // Render behind the input field
  z-index: -1;
}

.search-page__input-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 size(2);
}

.search-page__button {
  display: none;
  @include mq-gt($mq-450) {
    margin-left: size(1);
    display: inline-block;
  }
}

.search-page__loader {
  margin: size(5) 0 size(5) 0;
}

.search-page__help-link {
  margin-top: size(4);
  margin-bottom: size(8);
  text-align: center;
}

.search-page__no-result {
  margin-top: size(10);
  margin-bottom: size(8);
}
