@use '../../styles/yr' as *;

.profile-page {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-page__hero {
  @include h1;
  margin-top: size(8);
  margin-bottom: size(4);
}

.profile-page__ingress {
  @include large-text;
  max-width: size(45);
  margin-bottom: size(2);
}

.profile-page__my-profile-button,
.profile-page__log-out-button {
  margin-top: size(2);
  width: size(16);
}

.profile-page__home-link {
  margin-top: size(4);
}
