@use '../../styles/yr' as *;

.not-logged-in-page__heading {
  @include h1;

  margin-top: size(4);
  margin-bottom: size(3);

  @include mq-gt($mq-450) {
    margin-top: size(7);
    margin-bottom: size(2);
  }
}

.not-logged-in-page__subheading {
  @include large-text;

  margin-bottom: size(3);
}

.not-logged-in-page__login-button {
  margin-bottom: size(2);
  width: 100%;
  @include mq-gt($mq-450) {
    width: auto;
  }
}

.not-logged-in-page__details {
  @include small-text;
  display: flex;
  align-items: flex-start;

  @include mq-gt($mq-450) {
    align-items: center;
  }
}

.not-logged-in-page__details-icon {
  margin-right: size(0.5);
  margin-top: rem(2px);

  @include mq-gt($mq-450) {
    margin-top: 0;
  }
}
