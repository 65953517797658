@use '../../styles/yr' as *;

$breakpoint-one-row: 370px;
$breakpoint-one-row-with-date-label: 550px;

.register-page__heading {
  @include h1;

  margin-bottom: size(3);
}

.register-page__success {
  text-align: center;
}

.register-page__success-going-swimming {
  margin: size(6) auto size(2) auto;

  @include mq-lte($mq-450) {
    display: none;
  }
}

.register-page__success-diving {
  margin: size(10) auto size(8) auto;
  width: 100%;

  @include mq-gt($mq-450) {
    display: none;
  }
}

.register-page__success-heading {
  @include h1;

  margin-bottom: size(3);
}

.register-page__success-subheading {
  @include normal-text;

  margin-bottom: size(3);
}

.register-page__go-to-yr-button {
  @include mq-lte($mq-450) {
    width: 100%;
  }
}

.register-page__reset-button {
  display: block;
  text-decoration: underline;
  margin: size(2) auto 0 auto;

  @include hover {
    text-decoration: none;
  }
}

// We have a form container so we can position the loader
// animation in the middle of the form while submitting.
.register-page__form-container {
  position: relative;
}

.register-page__form {
  margin-bottom: size(4);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

// We hide the form while submitting so the loader animation
// is rendered on an empty background. We use `visibility: hidden`
// so the form container height doesn't change.
.register-page__form--submitting {
  visibility: hidden;
}

.register-page__form-fieldset {
  width: 100%;
}

.register-page__form-grid {
  display: grid;
  grid-gap: 0 size(2);
  grid-template-areas:
    'temperature-label temperature-label'
    'temperature-input temperature-input'
    'date-label time-label'
    'date-input time-input';
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto auto;

  @include mq-gte($breakpoint-one-row) {
    grid-template-areas:
      'temperature-label time-label time-label'
      'temperature-input date-input time-input';
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
  }

  @include mq-gte($breakpoint-one-row-with-date-label) {
    grid-template-areas:
      'temperature-label date-label time-label'
      'temperature-input date-input time-input';
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
  }
}

.register-page__temperature-label-cell {
  grid-area: temperature-label;
}

.register-page__temperature-input-cell {
  grid-area: temperature-input;
  margin-bottom: size(2);

  @include mq-gte($breakpoint-one-row) {
    margin-bottom: 0;
  }
}

.register-page__date-label-cell {
  $min-width: mq-gt-value($breakpoint-one-row);
  $max-width: mq-lt-value($breakpoint-one-row-with-date-label);

  // Hide the date label when the viewport is wide enough to show the input elements
  // on one row, but not wide enough to show both the date label and the time label.
  @media screen and (min-width: mq-gt-value($breakpoint-one-row)) and (max-width: $max-width) {
    @include visually-hidden;
  }
}

.register-page__date-input-cell {
  grid-area: date-input;
  min-width: rem(100px);
}

.register-page__time-label-cell {
  grid-area: time-label;
  margin-top: 0;
}

.register-page__time-input-cell {
  grid-area: time-input;
  min-width: rem(100px);
}

.register-page__form-label {
  @include h3;

  display: block;
  padding-bottom: size(1);
}

.register-page__error-list {
  width: 100%;
}

.register-page__error-message {
  margin-top: size(1);
}

.register-page__add-comment {
  margin-top: size(1);
}

.register-page__form-submit {
  margin-top: size(3);
  margin-bottom: size(2);
  width: 100%;

  @include mq-gt(450px) {
    max-width: rem(311px);
  }
}

.register-page__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.register-page__loader-text {
  margin-top: size(1);
}

.register-page__how-to-button {
  @include small-link;

  text-decoration: underline;
  margin-top: size(1);
  display: flex;
  align-items: center;
}

.register-page__how-to-icon {
  margin-right: size(0.5);
}

.register-page__details {
  @include small-text;

  margin-top: size(1);
  display: flex;
  align-items: center;
}

.register-page__details-icon {
  margin-right: size(0.5);
}

.register-page__map {
  height: rem(250px);
  margin: size(2) 0 size(4) 0;
}

.register-page__water-temperature-list-heading {
  @include h2;

  margin-bottom: size(2);
}
