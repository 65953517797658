@use '../../styles/yr' as *;

.button {
  @include h3;

  border-radius: rem(8px);
  border: 1px solid transparent;
  display: inline-block;
  text-decoration: none;
  vertical-align: top;
  text-align: center;

  // Since we have a 1px border on all side we have to remove that from padding
  padding: rem(11px) rem(23px);
}

.button--primary {
  background-color: $color-deep-blue;
  color: $color-white;

  @include hover {
    background-color: $color-mid-blue;
  }

  &:active {
    background-color: $color-deep-blue;
  }
}

.button--secondary {
  background-color: $color-light-blue;
  border-color: $color-mid-blue;
  color: $color-deep-blue;

  @include hover {
    background-color: $color-deep-blue;
    border-color: $color-mid-blue;
    color: $color-white;
  }

  &:active {
    background-color: $color-mid-blue;
    border-color: transparent;
    color: $color-white;
  }
}

.button:disabled {
  background-color: $color-disabled-blue;
  border-color: transparent;
  color: $color-pale-blue;
}

.button__invisible-label {
  @include visually-hidden;
}
