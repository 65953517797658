@use 'sass:math';
@use '../../styles/yr' as *;

.input {
  height: size(6);
  line-height: size(3);
  position: relative;

  // Make all numbers the same size
  font-variant-numeric: tabular-nums;

  // We disable ligatures so e.g. the `:` in `12:` won't change appearance
  // when you type another number `12:0`.
  font-variant-ligatures: none;

  // Prevent z indexes within this element from being global
  z-index: 0;
}

.input__input {
  @include input-text;

  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: math.div(size(3), 2);

  &::placeholder {
    color: $color-pale-blue;
  }

  @include hover {
    & + .input__background {
      border: 2px solid $color-mid-blue;
    }
  }

  &:active,
  &:focus {
    outline: none;

    & + .input__background {
      border: 2px solid $color-deep-blue;
    }
  }
}

.input__input--error {
  color: $color-error-red;

  + .input__background {
    background-color: $color-warning-red;
    border: 1px solid $color-error-red;
  }
}

.input__background {
  border-radius: rem(8px);
  border: 1px solid $color-pale-blue;
  background-color: $color-white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  // Render behind the input field
  z-index: -1;
}

// Hide the spinner from <input type="number">
// See https://css-tricks.com/snippets/css/turn-off-number-input-spinners/
.input__input[type='number']::-webkit-inner-spin-button,
.input__input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Hide the clear button from <input type="time">
// See https://stackoverflow.com/a/19655421/656578
.input__input[type='time']::-webkit-clear-button {
  display: none;
}

.input__fake-value {
  background-color: $color-white;
  position: absolute;
  pointer-events: none;
  right: math.div(size(3), 2);
  bottom: math.div(size(3), 2);
  left: math.div(size(3), 2);
}
