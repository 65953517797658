$color-deep-blue: #003792;
$color-mid-blue: #4368b6;
$color-pale-blue: #6b9eda;
$color-panel-light-blue: #defbff;
$color-light-blue: #90d7ff;
$color-disabled-blue: #c0d9e8;
$color-yr-blue: #00b8f1;
$color-white: #ffffff;
$color-black: #21292b;
$color-error-red: #e51d10;
$color-warning-red: #fcf2f2;
