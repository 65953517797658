@use '../../styles/yr' as *;

.dialog {
  bottom: 0;
  left: 0;
  overflow: scroll;
  overscroll-behavior: contain;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-overflow-scrolling: touch;

  // Make all z indexes local
  z-index: 0;
}

.dialog:after {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-black;
  opacity: 0.3;

  // Render behind `.dialog__core-dialog`
  z-index: -1;
}

.dialog__core-dialog {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  min-height: 100%;
  position: absolute;
  right: 0;
  top: 0;

  // Set z index so CoreDialog won't automatically set one
  z-index: 0;
}

.dialog__visible-dialog {
  background-color: $color-white;
  border-radius: rem(8px);
  box-shadow: $box-shadow-elevated;
  margin: size(3) 0;
  max-width: 100%;
  padding: size(4);
  position: relative;
  width: size(85);

  .dialog--small & {
    width: size(57);
  }

  .dialog--large & {
    width: size(85);
  }
}

.dialog__close-button {
  position: absolute;
  top: size(2);
  right: size(2);
}
