@use '../../styles/yr' as *;

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: size(5);
  margin: size(2) 0;
}

.header__title {
  margin-right: size(4);
}

.header__title-text-container {
  display: flex;
  align-items: center;
  position: relative;
}

.header__title-link {
  display: block;
  text-decoration: none;
}

.header__title-label {
  @include h3;

  margin-left: size(2);
}

.header__user {
  display: flex;
  align-items: center;
  text-decoration: none;

  // Ensure the width of the user element can shrink less than
  // the content so that `text-overflow: ellipsis` on the
  // `.header__user-link` will work.
  min-width: 0;
}

.header__user-icon {
  margin-right: size(1);
}

.header__user-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: none;

  @include mq-gt(350px) {
    display: inline;
  }
}
