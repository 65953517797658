@use '../../styles/yr' as *;

.app {
  height: 100%;
  position: relative;
  z-index: 0;
}

.app__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.app__icon-spritesheet {
  @include visually-hidden;
}
