@use 'sass:math';
@use "yr" as *;
@use "normalize";
@use "unsupported-browser";

// Update box model
*,
*:after,
*:before {
  box-sizing: border-box;
}

// Inherit all text styles and reset margins, borders, and paddings to 0
* {
  font-size: inherit;
  font-weight: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  border: none;
  margin: 0;
  padding: 0;
}

// Get rid of the default close button in input
::-ms-clear {
  display: none;
}

// Get rid of Firefox's default focus style
::-moz-focus-inner {
  border: 0;
}

// Custom global focus outline
:focus {
  outline: 3px $color-mid-blue;

  // Only show a focus outline when interacting with elements using a keyboard
  .dynamic:not(.is-tabbing) & {
    outline: none;
  }

  // Use default focus style in WebKit based browsers
  @supports (outline: 5px auto -webkit-focus-ring-color) {
    outline: 5px auto -webkit-focus-ring-color;
  }
}

html {
  background-color: $color-light-blue;
  height: 100%;

  // Create a base font size of 15px
  font-size: math.div(15px, 16px) * 1em;
}

body {
  @include normal-text;

  color: $color-deep-blue;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif;
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  // Prevent elements with `width: 100vw` like `FullWidth` from causing
  // a horizontal scrollbar when the page is tall enough to have a
  // vertical scrollbar.
  overflow-x: hidden;
}

#root {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: underline;

  @include hover {
    text-decoration: none;
  }
}

// Remove the default dotted border below <abbr> elements
abbr {
  text-decoration: none;
}

// Remove list style from lists by default since it looks odd when padding has been reset to 0,
// and because they most likely will need a unique style in any ase.
ol,
ul {
  list-style: none;
}

// Make img and svg blocks to get rid of the bottom gap that occurs
// when they are aligned with the text baseline and to make make them
// them affect the size of wrapper elements so they're easier to position.
img,
svg {
  display: block;
}

// <use> on some platforms causes problems for our global click handler
svg {
  pointer-events: none;
}

// Give elements a max width of 100% to prevent them from ever
// growing larger than the viewport.
img {
  max-width: 100%;
}

svg {
  fill: none;
}
