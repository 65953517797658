@use 'sass:math';
@use '../../styles/yr' as *;

.water-temperature-list__table {
  border-collapse: collapse;
  width: 100%;
}

.water-temperature-list__item {
  @include normal-text;

  color: $color-deep-blue;
  padding: math.div(size(3), 2) size(1);
  border-bottom: 1px solid $color-pale-blue;

  &:first-child {
    border-top: 1px solid $color-pale-blue;
  }

  @include mq-lt($mq-450) {
    padding: size(1) size(0.5);
    display: grid;
    grid-template-areas:
      'time time temperature'
      'type type temperature';
  }
}

.water-temperature-list__table-header {
  @include visually-hidden;
}

.water-temperature-list__time,
.water-temperature-list__type,
.water-temperature-list__temperature {
  padding: math.div(size(3), 2) size(1);
  white-space: nowrap;

  @include mq-lt($mq-450) {
    padding: 0;
  }
}

.water-temperature-list__time {
  width: 50%;

  @include mq-lt($mq-450) {
    grid-area: time;
    @include h3;
  }
}

.water-temperature-list__type {
  width: 100%;

  @include mq-lt($mq-450) {
    grid-area: type;
    @include small-text;
  }
}

.water-temperature-list__temperature {
  @include mq-lt($mq-450) {
    @include large-text;
    justify-self: end;
    align-self: center;
    grid-area: temperature;
  }
}
