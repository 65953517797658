@use 'yr' as *;

.unsupported-browser {
  text-align: center;
}

.unsupported-browser__illustration {
  margin: size(13) auto size(4) auto;
}

.unsupported-browser__heading {
  @include h1;

  margin-bottom: size(2);
}

.unsupported-browser__text-container {
  @include large-text;
}

.unsupported-browser__login-button {
  margin-top: size(5);
  margin-bottom: size(4);
}
