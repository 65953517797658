@use '../../styles/yr' as *;

.bathing-spots-combo-box {
  position: relative;
}

.bathing-spots-combo-box__container {
  display: flex;
}

.bathing-spots-combo-box__button {
  display: none;

  @include mq-gt($mq-450) {
    display: inline-block;
    margin-left: size(1);
  }
}

.bathing-spot-search-input {
  position: relative;
  width: 100%;
  z-index: 0;
}

.bathing-spot-search-input__input {
  @include input-text;

  position: relative;
  height: size(6);
  width: 100%;
  padding-left: size(6);
  border-radius: size(1);
  border: 0;
  background-color: transparent;

  &::placeholder {
    color: $color-pale-blue;
  }

  @include hover {
    & + .bathing-spot-search-input__background {
      border: 2px solid $color-mid-blue;
    }
  }

  &:active,
  &:focus {
    outline: none;

    & + .bathing-spot-search-input__background {
      border: 2px solid $color-deep-blue;
    }
  }
}

.bathing-spot-search-input__background {
  border-radius: rem(8px);
  border: 0;
  background-color: $color-white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  // Render behind the input field
  z-index: -1;
}

.bathing-spot-search-input__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 size(2);
}

.suggested-bathing-spots {
  position: absolute;
  width: 100%;
  background-color: white;
  box-shadow: $box-shadow-elevated;
  margin-top: size(1);
  border-radius: size(1);

  // core-suggest will add <mark> elements to highlight the current search word.
  // This removes the default <mark> style
  mark {
    background: none;
    color: inherit;
  }
}

.suggested-bathing-spots__item {
  height: size(7);
  border-bottom: 1px solid $color-pale-blue;
  padding: size(1);

  &:first-child {
    border-top-left-radius: size(1);
    border-top-right-radius: size(1);
  }

  &:last-child {
    border-bottom-left-radius: size(1);
    border-bottom-right-radius: size(1);
    border: 0;
  }

  &:active {
    background-color: $color-pale-blue;
    color: $color-white;
  }

  &:focus-within,
  &:hover {
    background-color: $color-deep-blue;
    color: $color-white;
  }
}

.suggested-bathing-spots__link {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  height: 100%;
  &:focus {
    outline: none;
  }
}

.suggested-bathing-spots__show-more {
  align-self: center;
}

.suggested-bathing-spots__icon {
  position: relative;
  align-self: center;
  margin: 0 size(1) 0 size(1);
}

.suggested-bathing-spots__location {
  display: flex;
  flex-direction: column;
}

.suggested-bathing-spots__location-name {
  @include h3;
}

.suggested-bathing-spots__region {
  @include small-text;
}
