@mixin visually-hidden() {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
}

.visually-hidden {
  @include visually-hidden();
}
