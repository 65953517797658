@use '../../styles/yr' as *;

.accept-consent-page__heading {
  @include h1;

  margin-top: size(3);
  margin-bottom: size(2);
}

.accept-consent-page__subheading {
  @include large-text;

  margin-bottom: size(2);
}

.accept-consent-page__details {
  margin-bottom: size(3);
}

.accept-consent-page__button {
  width: 100%;
  margin-bottom: size(6);

  @include mq-gt($mq-450) {
    width: auto;
    margin-bottom: size(16);
  }
}

.accept-consent-page__going-swimming {
  margin: 0 auto;
  @include mq-gt($mq-450) {
    display: none;
  }
}
