// Add a mouse hover effect that is also applied when the element is focused with a keyboard
@mixin hover($focus-within: false) {
  @media (hover: hover) {
    &:hover {
      @content;
    }

    // Show the hover effect when an element within has focus also
    @if ($focus-within) {
      &:focus-within {
        @content;
      }
    }
  }
}
