@use '../../styles/yr' as *;

.error-message {
  @include small-text;

  background-color: $color-error-red;
  border-radius: 8px;
  color: $color-white;
  display: flex;
  margin-top: size(1);
  padding: size(1);
}

.error-message__icon {
  margin-right: size(0.5);

  // Adjust the icon so it's vertically aligned with the first line
  // of the error message even when the error message is several
  // lines long.
  position: relative;
  top: rem(2px);
}
