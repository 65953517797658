@use '../../styles/yr' as *;

.add-comment__button {
  display: flex;
}

.add-comment__button-icon {
  align-self: center;
  border: 1.5px solid $color-deep-blue;
  border-radius: 100%;
  margin-right: size(1);
  padding: 1px;
  @include mq-gt($mq-675) {
    display: none;
  }
}

.add-comment__label-text,
.add-comment__button {
  @include small-text;

  // First span of the label text should have larger text
  :first-child {
    @include h3;
  }
}

.add-comment__area {
  display: grid;
}

.add-comment__area--hidden {
  display: none;
}

.add-comment__area-info {
  @include small-text;
  margin-top: size(1);
}

.add-comment__area-text-area-wrapper {
  display: grid;
  position: relative;

  &:after {
    // We add the extra space to avoid jumpy behaviour
    // https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
    content: attr(data-replicated-value) ' ';

    // Same behaviour as textarea
    white-space: pre-wrap;
    visibility: hidden;
  }
}

.add-comment__area-text-area-wrapper > textarea,
.add-comment__area-text-area-wrapper::after {
  @include input-text;

  grid-area: 1 / 1 / 2 / 2;
  border: 1px solid $color-pale-blue;
  border-radius: 8px;
  box-sizing: content-box;
  word-break: break-all;
  padding: 12px 12px 20px 12px;
}

.add-comment__area-text-area {
  resize: none;

  // Firefox shows scrollbar each time the area grows, so we hide overflow to avoid that
  overflow: hidden;

  &:active,
  &:focus {
    outline: none;
    border-radius: 8px;
    border: 2px solid $color-deep-blue;
    padding: 11px 11px 19px 11px;
    box-sizing: content-box;
  }
}

.add-comment__area-counter {
  position: absolute;
  bottom: size(0.5);
  right: size(1);
  @include small-text;
  color: $color-mid-blue;
}
