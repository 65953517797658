@use 'sass:math';
@use '../../styles/yr' as *;
@use '../Page/Page' as page;

$close-button-size: 3;

.banner {
  align-items: center;
  background-color: $color-deep-blue;
  color: $color-white;
  display: flex;
  justify-content: center;
  padding: math.div(size(3), 2) size(page.$horizontal-padding);
  position: relative;
  text-align: left;
  width: 100%;
}

.banner__text {
  // Make room for the close button on the right side.
  // We use the same padding on the left side to ensure
  // the text is correctly centered horizontally.
  padding: 0 size(page.$horizontal-padding + $close-button-size);

  // Render the banner text with the same max width as the page body
  // when the viewport is small enough. A media query of 770px
  // seems to work fine for this.
  @include mq-lte(770px) {
    max-width: size(page.$max-width);
    padding-left: 0;
  }
}

.banner__close-button {
  position: absolute;
  right: size(2);
  top: math.div(size(3), 2);
}
