@use 'sass:math';
@use '../../styles/yr' as *;

.select {
  height: size(6);
  line-height: size(3);
  position: relative;

  // Prevent z indexes within this element from being global
  z-index: 0;
}

.select__select {
  @include input-text;

  appearance: none;
  background-color: transparent;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  // We add extra padding on the right because of the icon
  padding-top: math.div(size(3), 2);
  padding-right: size(3) + size(1) + math.div(size(3), 2);
  padding-bottom: math.div(size(3), 2);
  padding-left: math.div(size(3), 2);

  @include hover {
    & + .select__background {
      border: 2px solid $color-mid-blue;
    }
  }

  &:active,
  &:focus {
    outline: none;

    & + .select__background {
      border: 2px solid $color-deep-blue;
    }
  }
}

.select__background {
  border-radius: rem(8px);
  border: 1px solid $color-pale-blue;
  background-color: $color-white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  // Render behind the input field
  z-index: -1;
}

.select__icon {
  position: absolute;
  right: math.div(size(3), 2);
  top: 50%;
  transform: translateY(-50%);
}
