@use '../../styles/yr' as *;

.login-error-page {
  text-align: center;
}

.login-error-page__illustration {
  margin: size(13) auto size(4) auto;
}

.login-error-page__heading {
  @include h1;

  margin-bottom: size(2);
}

.login-error-page__text {
  @include large-text;
}

.login-error-page__login-button {
  margin-top: size(5);
  margin-bottom: size(4);
}
