@use '../../styles/yr' as *;

@keyframes loader__bounce-animation {
  0% {
    transform: scale(0.3);
  }
  100% {
    transform: scale(1);
  }
}

.loader {
  align-items: center;
  display: flex;
  height: size(2);
  justify-content: center;
}

.loader__animation-bounce {
  background-color: $color-deep-blue;
  border-radius: 50%;
  display: inline-block;
  height: rem(13px);
  width: rem(13px);

  &:nth-child(1) {
    animation: loader__bounce-animation 400ms linear 0ms infinite alternate-reverse;
  }

  &:nth-child(2) {
    animation: loader__bounce-animation 400ms linear -200ms infinite alternate-reverse;
  }

  &:nth-child(3) {
    animation: loader__bounce-animation 400ms linear -400ms infinite alternate-reverse;
  }
}
