@use "line-height" as *;
@use "rem" as *;
@use "media-queries" as *;

@mixin h1() {
  font-size: rem(42px);
  font-weight: 800;
  line-height: line-height(48px);

  @include mq-lte($mq-450) {
    font-size: rem(28px);
    line-height: line-height(34px);
  }
}

@mixin h2() {
  font-size: rem(22px);
  font-weight: bold;
  line-height: line-height(32px);
  @include mq-lte($mq-450) {
    font-size: rem(18px);
    line-height: line-height(28px);
  }
}

@mixin h3() {
  font-size: rem(15px);
  font-weight: 500;
  line-height: line-height(24px);
}

@mixin large-text() {
  font-size: rem(18px);
  font-weight: normal;
  line-height: line-height(26px);
}

@mixin normal-text() {
  font-size: rem(15px);
  font-weight: normal;
  line-height: line-height(24px);
}

@mixin small-text() {
  font-size: rem(13px);
  font-weight: normal;
  line-height: line-height(18px);
}

@mixin small-link() {
  font-size: rem(12px);
  font-weight: normal;
  line-height: line-height(18px);
}

// Prevent iOS from zooming in when the select element is focused
@mixin input-text() {
  font-size: rem(16px);
  line-height: line-height(18px);
}
