@use '../../styles/yr' as *;

.search-location-list__link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: $color-white;
  padding: size(1) size(2);
  border-radius: rem(8px);
  border: 1px solid $color-pale-blue;
  margin: size(2) 0;
  text-decoration: none;

  @include hover {
    background-color: $color-deep-blue;
    color: $color-white;
  }

  &:active {
    background-color: $color-pale-blue;
    color: $color-white;
  }
}

.search-location-list__icon {
  margin-right: size(1);
}

.search-location-list__item-text-wrapper {
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.search-location-list__location {
  @include h3;
}

.search-location-list__region {
  @include small-text;
}
