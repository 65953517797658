@use '../../styles/yr' as *;

$max-width: 72;
$horizontal-padding: 2;

.page {
  display: grid;
  height: 100%;
  margin: 0 auto;
  padding: 0 size($horizontal-padding);
  max-width: size($max-width + 2 * $horizontal-padding);
}

.page__content {
  display: grid;
  grid-template-rows: [header] auto [body] 1fr;
  // We need to prevent grid blowout, make sure column does not have min-width: auto which is the default.
  grid-template-columns: minmax(0, 1fr);
}

.page__body {
  max-width: size($max-width);
}
