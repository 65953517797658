@use 'sass:math';

$mq-450: 450px;
$mq-600: 600px;
$mq-640: 640px;
$mq-675: 675px;
$mq-750: 750px;
$mq-990: 990px;
$mq-1350: 1350px;

// To prevent overlapping breakpoints we add or subtract `0.01em`.
// See http://tzi.fr/css/prevent-double-breakpoint#A-separation-in-em
// We define media queries using `em` and not `rem` or `px`
// because of better browser compatibility.
// See https://zellwk.com/blog/media-query-units/
// The base font size for the `em()` helper is `16px` and not `15px`
// which our base font size is because media queries in ems not affected
// by the base font size.
@mixin mq-lt($width-in-pixels) {
  @media screen and (max-width: mq-lt-value($width-in-pixels)) {
    @content;
  }
}

@mixin mq-lte($width-in-pixels) {
  @media screen and (max-width: mq-lte-value($width-in-pixels)) {
    @content;
  }
}

@mixin mq-gte($width-in-pixels) {
  @media screen and (min-width: mq-gte-value($width-in-pixels)) {
    @content;
  }
}

@mixin mq-gt($width-in-pixels) {
  @media screen and (min-width: mq-gt-value($width-in-pixels)) {
    @content;
  }
}

@function mq-lt-value($width-in-pixels) {
  @if unit($width-in-pixels) != 'px' {
    @error "$width-in-pixels must be a pixel value.";
  }

  $width-in-ems: math.div($width-in-pixels, 16px) + 0em;
  @return $width-in-ems - 0.01em;
}

@function mq-lte-value($width-in-pixels) {
  @if unit($width-in-pixels) != 'px' {
    @error "$width-in-pixels must be a pixel value.";
  }

  $width-in-ems: math.div($width-in-pixels, 16px) + 0em;
  @return $width-in-ems;
}

@function mq-gte-value($width-in-pixels) {
  @if unit($width-in-pixels) != 'px' {
    @error "$width-in-pixels must be a pixel value.";
  }

  $width-in-ems: math.div($width-in-pixels, 16px) + 0em;
  @return $width-in-ems;
}

@function mq-gt-value($width-in-pixels) {
  @if unit($width-in-pixels) != 'px' {
    @error "$width-in-pixels must be a pixel value.";
  }

  $width-in-ems: math.div($width-in-pixels, 16px) + 0em;
  @return $width-in-ems + 0.01em;
}
