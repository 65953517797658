@use '../../styles/yr' as *;

.home-page__heading {
  @include h1;

  margin-top: size(2);
  margin-bottom: size(2);

  @include mq-gt($mq-450) {
    margin-top: size(4);
  }
}

.home-page__bathing-spot-list-heading {
  @include h2;

  margin-top: size(4);
  margin-bottom: size(2);

  @include mq-gt($mq-450) {
    margin-top: size(5);
  }
}

.home-page__bathing-spot-list {
  margin-bottom: size(4);
}
