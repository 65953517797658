@use '../../styles/yr' as *;

.bathing-spot-list__item {
  @include h3;

  background: $color-deep-blue;
  border: 1px solid transparent;
  color: $color-white;
  border-radius: rem(8px);

  @include hover {
    background-color: $color-mid-blue;
  }

  &:active {
    background-color: $color-deep-blue;
  }

  & + & {
    margin-top: size(2);
  }
}

.bathing-spot-list__item-link {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  padding: size(1.5) size(2);
}

.bathing-spot-list__name {
  flex: 1;
  margin: 0 size(1);
}
