@use '../../styles/yr' as *;

.full-width-standing-on-island-illustration {
  @include mq-lte($mq-750) {
    display: none;
  }
}

.full-width-standing-on-island-illustration__illustration {
  margin: size(7) auto size(14) auto;
  width: rem(788px);
  max-width: 100%;
}
